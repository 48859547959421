import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {bookyRequest} from "../utils/request-utils";
import {useAuth} from "./useAuth";
const {Set} = require('immutable');

const BookyContext = createContext(null);

export const BookyProvider = ({ children }) => {
    const {user, logout} = useAuth()
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inFlight, setInFlight] = useState(Set());

    useEffect(() => {
        setLoading(inFlight.size > 0);
    }, [inFlight])

    const request = useCallback(async (method, url, body = null) => {
        let uuid = Math.random().toString() + new Date().toString();
        setInFlight(draft => { return draft.add(uuid) })
        setError(null);
        try {
            return await bookyRequest(user || undefined, method, url, body);
        } catch (e) {
            if (e.message === "Not logged in") {
                logout();
            }
            setError(e.message);
            throw e;
        } finally {
            setInFlight(draft => {return draft.remove(uuid)});
        }
    }, [user, logout])

    const clearError = () => { setError(null); }

    const value = {
        request,
        loading,
        error,
        clearError,
    };

    return <BookyContext.Provider value={value}>{children}</BookyContext.Provider>;
};

export const useBooky = () =>{
    return useContext(BookyContext);
}
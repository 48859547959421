import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {Layout} from "./components/Layout";
import localedk from "date-fns/locale/da";
import {registerLocale} from "react-datepicker";
import BookingPage from "./components/Booking/BookingPage";
import ConfirmationPage from "./components/Booking/ConfirmationPage";
import ProfilePage from "./components/Profile/ProfilePage";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import PaymentReturn from "./components/Booking/PaymentReturn";
import MembershipsPage from "./components/Membership/MembershipsPage";
import {getHost} from "./utils/request-utils";

registerLocale("da", localedk);

const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "pk_live_51Lwi1IKu2x9m3ZbcIPoTeZbsoIBBYUTA7HjuDBRf4LDM6PZz9f9CP5IJ2kwglmUVmnObxv6XmcqLBVrNmJFAiLQI00JMFiDXEp";

const stripePromise = loadStripe(stripeKey, {
    locale: 'da',
});


// Look up data for the portal
document.addEventListener('DOMContentLoaded', function() {
    (async () => {
        const params = new URLSearchParams(document.location.search);
        let companyID = params.get('companyID');
        let hostname = undefined;
        if (!companyID) {
            companyID = sessionStorage.getItem('bookyCompanyID');
            if (!companyID) {
                hostname = params.get('hostname')
                if (!hostname) {
                    hostname = document.location.hostname;
                }
            }
        }

        let u = getHost() + "/api/v1/portalSettings";
        if (companyID) {
            u += "?companyID=" + encodeURIComponent(companyID);
        } else {
            u += '?hostname=' + encodeURIComponent(hostname);
        }

        const response = await fetch(u);
        if (!response.ok) {
            document.write("Could not open booking portal: " + response.statusText + " - " + u + "<br />Skriv eventuelt til info@urban-golf.dk med denne besked. Du kan også skrive eller ringe for at booke.");
            throw Error("Could not open booking portal: " + response.statusText + " - " + u);
        }
        const ret = await response.json()
        sessionStorage.setItem('bookyCompanyID', ret.data.companyID);
        return ret.data;

    })().then(portalData => {

        const router = createBrowserRouter(
            createRoutesFromElements(
                <Route element={<Layout portalData={portalData}/>}>
                    <Route path="/" element={<App />}/>
                    <Route path="/slots" element={<BookingPage/>}/>
                    <Route path="/confirmation/:id" element={<ConfirmationPage/>}/>
                    <Route path="/memberships" element={<MembershipsPage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/paymentReturn/:id" element={<PaymentReturn />}/>
                </Route>
            )
        );

        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Elements stripe={stripePromise}>
              <React.StrictMode>
                <RouterProvider router={router}/>
              </React.StrictMode>
            </Elements>
        );

    }).catch(e => {
        console.log(e);
        throw e;
    })
}, false);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
